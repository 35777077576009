<template>
    
    <div class="modal fade" id="offerModal" tabindex="-1" aria-labelledby="offerModalLabel" aria-hidden="true" v-if="$props.type == 'Аренда' || $props.type == 'Покупка'">
    <div class="modal-dialog" v-click-outside="clearFiles">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="offerModalLabel">Редактировать объект</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть" @click="clearFiles()"></button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="uploadEditItem()" id="editForm" class="w-100">

                        <select class="form-select py-2 mb-4" v-model="editItem.type" required>
                            <option value="" selected>Выбрать тип</option>
                            <option>Аренда</option>
                            <option>Покупка</option>
                        </select>
                        <p class="mb-1">Номер объекта</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.number" placeholder="Номер объекта" required>
                        <p class="mb-1">Название</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.title" placeholder="Дом на острове Самуи" required>
                        <p class="mb-1">Описание</p>
                        <textarea rows="5" class="form-control mb-4" v-model="editItem.description" required></textarea>
                        <p class="mb-1">Фото</p>
                        <input type="file" id="images" ref="images" class="form-control mb-4" @change="handleFilesChangedUpload()" multiple="multiple">
                        <!--<input type="file" id="images" ref="images" class="form-control mb-4 d-none" multiple="multiple">
                        <div v-for="imgInput in imgInputs" :key="imgInput" class="w-100 mb-4">
                             <input type="file" class="form-control mb-2" @change="handleFilesUpload(imgInput-1)">
                             <img :src="imagesPreview[imgInput-1]" alt="" class="w-50">

                        </div>-->
                       
                        <!--<a class="d-block" @click="imgInputs++">Добавить поле</a>
                        <a class="text-danger d-block" @click="deleteImgInput()">Удалить поле</a>-->
                        <p class="mb-1">Кол-во спален</p>
                        <input type="number" class="form-control mb-4" placeholder="3" v-model="editItem.bedrooms" required>
                        <p class="mb-1">Кол-во санузлов</p>
                        <input type="number" class="form-control mb-4" placeholder="100" v-model="editItem.bathrooms">
                        
                        <select class="form-select py-2 mt-4" v-model="editItem.place" required>
                            <option value="" selected>Выбрать район</option>
                            <option>Липаной (Lipa Noi)</option>
                            <option>Натон (Nathon)</option>
                            <option>БангПо (Bang Po)</option>
                            <option>БанТай (Ban Tai)</option>
                            <option>Майнам (Maenam)</option>
                            <option>БоПут (Bo Phut)</option>
                            <option>БангРак (BangRak)</option>
                            <option>ПлайЛаем (Plai Laem)</option>
                            <option>ЧонгМон (Cheng Mon)</option>
                            <option>Чавенг (Chaweng)</option>
                            <option>Чавенг Ной (Chaweng Noi)</option>
                            <option>Ламай (Lamai)</option>
                            <option>ХуаТанон (Hua Thanon) </option>
                            <option>Талингнам (Taling Ngam) </option>
                        </select>

                        <p class="mb-1">Расстояние до пляжа (м)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.beach">
                        <p class="mb-1">Расстояние до магазина (м)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.shop">

                        <p class="mb-1">Площадь (м2)</p>
                        <input type="number" class="form-control mb-4" placeholder="100" v-model="editItem.size">

                        <p class="mb-1">Цена (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="100000" v-model="editItem.price" required>

                        <p class="mt-4 mb-1">Депозит (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="10000" v-model="editItem.deposit">

                        <p class="mt-4 mb-1">Комиссия агента (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="50" v-model="editItem.commission">

                        <p class="mb-1">Ст-мость электричества за 1кВт (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.energy">

                        <p class="mb-1">Ст-мость воды (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.water">

                        <p class="mb-2">Расчет воды за:</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="water_1" v-model="editItem.waterType">
                                <label class="form-check-label" for="water_1">
                                    месяц
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="water_2" v-model="editItem.waterType">
                                <label class="form-check-label" for="water_2">
                                    человека в месяц
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="2" id="water_3" v-model="editItem.waterType">
                                <label class="form-check-label" for="water_3">
                                    за 1 куб
                                </label>
                            </div>
                        </div>

                        <p class="mt-4 mb-1">Ст-мость интернета (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.internetPrice">

                        <p class="mt-4 mb-1">Скорость интернета (мб/с)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.internetSpeed">

                        <p class="mb-1">Ст-мость уборки перед выездом (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.cleaning">

                        <p class="mb-1">Актуальность</p>
                        <select class="form-select py-2 mb-2" v-model="editItem.status" required>
                            <option>Под запрос</option>
                            <option>Свободен</option>
                            <option>Занят</option>
                        </select>
                        <input type="text" class="form-control mb-4" placeholder="до 1 августа" v-model="editItem.status_text">

                        <p class="mb-1">Контакты для бронирования</p>
                        <input type="text" class="form-control mb-4" placeholder="@samui_31" v-model="editItem.contacts">
                        
                        
                        <p class="mt-4 mb-2">Удобства</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_9" v-model="editItem.pool">
                                <label class="form-check-label" for="service_edit_9">
                                    Бассейн
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_1" v-model="editItem.gym">
                                <label class="form-check-label" for="service_edit_1">
                                    Тренажерный зал
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_15" v-model="editItem.cond">
                                <label class="form-check-label" for="service_15">
                                    Кондиционер
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_5" v-model="editItem.tv">
                                <label class="form-check-label" for="service_edit_5">
                                    Телевизор
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_2" v-model="editItem.dw">
                                <label class="form-check-label" for="service_edit_2">
                                    Посудомоечная машина
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_3" v-model="editItem.fridge">
                                <label class="form-check-label" for="service_edit_3">
                                    Холодильник
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_4" v-model="editItem.wm">
                                <label class="form-check-label" for="service_edit_4">
                                    Стиральная машина
                                </label>
                            </div>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_6" v-model="editItem.internet">
                                <label class="form-check-label" for="service_edit_6">
                                    Интернет
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_7" v-model="editItem.plate">
                                <label class="form-check-label" for="service_edit_7">
                                    Плита
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_16" v-model="editItem.oven">
                                <label class="form-check-label" for="service_16">
                                    Духовка
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_17" v-model="editItem.hood">
                                <label class="form-check-label" for="service_17">
                                    Вытяжка
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_8" v-model="editItem.micro">
                                <label class="form-check-label" for="service_edit_8">
                                    Микроволновка
                                </label>
                            </div>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_10" v-model="editItem.kettle">
                                <label class="form-check-label" for="service_edit_10">
                                    Чайник
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_12" v-model="editItem.coffee">
                                <label class="form-check-label" for="service_edit_12">
                                    Кофемашина
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_11" v-model="editItem.toster">
                                <label class="form-check-label" for="service_edit_11">
                                    Тостер
                                </label>
                            </div>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_13" v-model="editItem.multi">
                                <label class="form-check-label" for="service_edit_13">
                                    Мультиварка
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" :true-value="1" :false-value="0" id="service_edit_14" v-model="editItem.blender">
                                <label class="form-check-label" for="service_edit_14">
                                    Блендер
                                </label>
                            </div>
                        </div>

                        <p class="mt-4 mb-2">Особенности</p>
                        <div class="ms-3">
                            <label class="mb-2">С животными:</label>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="special_1" v-model="editItem.animals">
                                <label class="form-check-label" for="special_1">
                                    Разрешается
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="2" id="special_2" v-model="editItem.animals">
                                <label class="form-check-label" for="special_2">
                                    Обсуждается
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="special_3" v-model="editItem.animals">
                                <label class="form-check-label" for="special_3">
                                    Запрещается
                                </label>
                            </div>
                        </div>

                       

                        <p class="mb-1">Локация</p>
                        <!--<textarea rows="5" class="form-control mb-4" v-model="editItem.map"></textarea>-->
                        <input type="file" id="imageMap" ref="imageMap" class="form-control mb-4" @change="handleFileUploadEditMap()">

                        <p class="mb-1">Видео</p>
                        <textarea rows="5" class="form-control mb-4" v-model="editItem.video"></textarea>

                        <p class="mb-1">Похожие объекты</p>
                        <textarea rows="5" class="form-control mb-4" v-model="editItem.analog"></textarea>

                        <!--<p class="mt-4 mb-2">Добавить в архив</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="hide_1" v-model="editItem.hide">
                                <label class="form-check-label" for="hide_1">
                                    Нет
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="hide_2" v-model="editItem.hide">
                                <label class="form-check-label" for="hide_2">
                                    Да
                                </label>
                            </div>
                        </div>-->

                        <button class="btn-white mt-5" style="float: right"><span :class="{'d-none': loader}">Редактировать объект</span>
                            <div class="spinner-border text-secondary" :class="{'d-none': !loader}" role="status">
                                <span class="visually-hidden">Загрузка...</span>
                            </div>
                        </button>
                    </form>
        </div>
      </div>
    </div>
  </div>

  <!--Редактирование транспорта-->

  <div class="modal fade" id="motoModal" tabindex="-1" aria-labelledby="motoModalLabel" aria-hidden="true" v-if="$props.type=='Транспорт'">
    <div class="modal-dialog" v-click-outside="clearFiles">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="motoModalLabel">Редактировать объект</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть" @click="clearFiles()"></button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="uploadEditItem()" id="editFormMoto" class="w-100">

                <select class="form-select py-2 mb-4" v-model="editItem.type" required>
                            <option value="" selected>Вид транспорта</option>
                            <option>Авто</option>
                            <option>Мотоцикл</option>
                            <option>Скутер</option>
                        </select>

                        <p class="mb-1">Номер транспорта</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.number" placeholder="Введите номер" required>

                        <p class="mb-1">Марка</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.mark" placeholder="Honda" required>

                        <p class="mb-1">Модель</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.model" placeholder="RX500" required>

                        <p class="mb-1" v-if="editItem.type == 'Авто'">Объем двигателя (л.)</p>
                        <p class="mb-1" v-else>Объем двигателя (куб.)</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.engine" placeholder="100" required>

                        <p class="mb-1">Год выпуска</p>
                        <input type="number" class="form-control mb-4" v-model="editItem.year" placeholder="2010" required>

                        <p class="mb-1">Пробег (км)</p>
                        <input type="number" class="form-control mb-4" v-model="editItem.probeg" placeholder="1000">

                        <p class="mb-1">Фото</p>
                        <input type="file" id="images" ref="images" class="form-control mb-4" multiple @change="handleFilesChangedUpload()">

                        <p class="mb-1">Описание</p>
                        <textarea rows="5" class="form-control mb-4" v-model="editItem.description"></textarea>
                        
                        <p class="mb-1">Стоимость за сутки (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="100" v-model="editItem.priceForDay">

                        <p class="mb-1">Стоимость за неделю (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="500" v-model="editItem.priceForWeek">

                        <p class="mb-1">Стоимость за месяц (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="2000" v-model="editItem.priceForMonth">

                        <p class="mb-1">Депозит (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="editItem.deposit">

                        <p class="mb-1">Контакты для бронирования</p>
                        <input type="text" class="form-control mb-4" placeholder="@samui_31" v-model="editItem.contacts">

                        <p class="mb-1">Локация</p>
                        <input type="file" id="imageMap" ref="imageMap" class="form-control mb-4" @change="handleFileUploadEditMap()">

                       <!-- <p class="mb-2">Публикация</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="hide_1" v-model="editItem.hide">
                                <label class="form-check-label" for="hide_1">
                                    Основной каталог
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="hide_2" v-model="editItem.hide">
                                <label class="form-check-label" for="hide_2">
                                    Архив
                                </label>
                            </div>
                        </div>-->

                        <button class="btn-white mt-5" style="float: right"><span :class="{'d-none': loader}">Редактировать транспорт</span>
                            <div class="spinner-border text-secondary" :class="{'d-none': !loader}" role="status">
                                <span class="visually-hidden">Загрузка...</span>
                            </div>
                        </button>
                    </form>
        </div>
      </div>
    </div>
  </div>

 <!--Редактирование новости-->

 <div class="modal fade" id="newsModal" tabindex="-1" aria-labelledby="newsModalLabel" aria-hidden="true" v-if="$props.type=='Новости'">
    <div class="modal-dialog" v-click-outside="clearFiles">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="newsModalLabel">Редактировать объект</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть" @click="clearFiles()"></button>
        </div>
        <div class="modal-body">
            <form @submit.prevent="uploadEditItem()" id="editFormMoto" class="w-100">

                        <p class="mb-1">Заголовок</p>
                        <input type="text" class="form-control mb-4" v-model="editItem.title" placeholder="Введите текст" required>
                        
                        <p class="mb-1">Текст новости</p>
                        <textarea rows="5" class="form-control mb-4" v-model="editItem.description" required></textarea>

                        <p class="mb-1">Фото</p>
                        <input type="file" id="images" ref="images" class="form-control mb-4" multiple @change="handleFilesChangedUpload()">

                        <p class="mb-1">Ссылка на видео</p>
                        <input type="text" class="form-control mb-4" placeholder="Введите ссылку" v-model="editItem.video">

                        <!--<p class="mb-2">Публикация</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="hide_1" v-model="editItem.hide">
                                <label class="form-check-label" for="hide_1">
                                    Основной каталог
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="hide_2" v-model="editItem.hide">
                                <label class="form-check-label" for="hide_2">
                                    Архив
                                </label>
                            </div>
                        </div>-->

                        <button class="btn-white mt-5" style="float: right"><span :class="{'d-none': loader}">Редактировать новость</span>
                            <div class="spinner-border text-secondary" :class="{'d-none': !loader}" role="status">
                                <span class="visually-hidden">Загрузка...</span>
                            </div>
                        </button>
                    </form>
        </div>
      </div>
    </div>
  </div>

  <!--Панель администратора-->
    <div class="container admin-page">
        <h1 class="catalog-page-title mb-5 text-center">Панель агента</h1>

                <div class="d-flex justify-content-between mb-3" v-if="navigation == 1 && ($props.type == 'Аренда' || $props.type == 'Покупка')">
                    <form @submit.prevent="applyFilter(0)" class="d-flex my-auto">
                        <input type="text" class="form-control" placeholder="Введите код" v-model="filter.number">

                        <div class="btn-group ms-2" role="group" aria-label="Basic radio toggle button group" style="height: fit-content;">
                            <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio1" autocomplete="off" disabled >
                            <label class="btn-white" for="btnradio1" style="border: none;"><img src="../assets/bedrooms.png" style="width: 25px;" alt=""></label>

                            <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio2" autocomplete="off" value="1" v-model="filter.bedroom">
                            <label class="btn btn-white" for="btnradio2">1</label>

                            <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio3" autocomplete="off" value="2" v-model="filter.bedroom">
                            <label class="btn btn-white" for="btnradio3">2</label>

                            <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio4" autocomplete="off" value="3" v-model="filter.bedroom">
                            <label class="btn btn-white" for="btnradio4">3</label>

                            <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio5" autocomplete="off" value="4" v-model="filter.bedroom">
                            <label class="btn btn-white" for="btnradio5" style="border-radius: 0;">4+</label>
                        </div>

                        <button class="btn-white ms-3 col-lg-3">Найти</button>
                    </form>
                    <div class="my-auto">
                        <a @click="navigation = 2" v-if="navigation == 1" class="text-success me-4" style="font-weight: 600;">Добавить объект</a>
                        <a @click="deleteItems" v-if="navigation == 1" class="text-danger" style="font-weight: 600;">Удалить выбранное</a>
                    </div>
                    
                </div>

                <div class="d-flex justify-content-between mb-3" v-if="navigation == 1 && $props.type == 'Транспорт'">
                    <form @submit.prevent="applyFilter(0)" class="d-flex my-auto">
                        <input type="text" class="form-control" placeholder="Введите код" v-model="filter.number">

                        <button class="btn-white ms-3 col-lg-3">Найти</button>
                    </form>
                    <div class="my-auto">
                        <a @click="navigation = 6" v-if="navigation == 1" class="text-success me-4" style="font-weight: 600;">Добавить транспорт</a>
                        <a @click="deleteItems" v-if="navigation == 1" class="text-danger" style="font-weight: 600;">Удалить выбранное</a>
                    </div>
                    
                </div>

                <div class="d-flex justify-content-end mb-3" v-if="navigation == 1 && $props.type == 'Новости'">
                    <div class="my-auto">
                        <a @click="navigation = 7" v-if="navigation == 1" class="text-success me-4" style="font-weight: 600;">Добавить новость</a>
                        <a @click="deleteItems" v-if="navigation == 1" class="text-danger" style="font-weight: 600;">Удалить выбранное</a>
                    </div>
                    
                </div>
                
                <div class="row px-0">
                    
                <div class="col-lg-2 col-12">
                    <div class="catalog-filter-block d-flex flex-column p-3 mt-2" style="border: 1px solid #ccc; border-radius: 10px; min-height: 400px; font-size: 0.9em;">

                        <a href="/agent/Аренда" class="mt-4">Недвижимость</a>
                        <a class="my-2" @click="navigation = 2" :class="{'fw-bold': navigation == 2}">- Добавить объект</a>
                        <a href="/agent/Аренда" :class="{'fw-bold': $props.type=='Аренда' && filter.place == '' && navigation == 1}">- Аренда</a>
                        <ul class="ps-2" style="list-style-type: none; font-size: 0.9em;" v-if="$props.type == 'Аренда' && navigation == 1">
                            <li><a @click="filter.place='Липаной (Lipa Noi)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Липаной (Lipa Noi)' && navigation == 1}">- Липаной </a></li>
                            <li><a @click="filter.place='Натон (Nathon)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Натон (Nathon)' && navigation == 1}">- Натон</a></li>
                            <li><a @click="filter.place='БангПо (Bang Po)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'БангПо (Bang Po)' && navigation == 1}">- БангПо </a></li>
                            <li><a @click="filter.place='БанТай (Ban Tai)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'БанТай (Ban Tai)' && navigation == 1}">- БанТай </a></li>
                            <li><a @click="filter.place='Майнам (Maenam)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Майнам (Maenam)' && navigation == 1}">- Майнам </a></li>
                            <li><a @click="filter.place='БоПут (Bo Phut)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'БоПут (Bo Phut)' && navigation == 1}">- БоПут </a></li>
                            <li><a @click="filter.place='БангРак (BangRak)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'БангРак (BangRak)' && navigation == 1}">- БангРак </a></li>
                            <li><a @click="filter.place='ПлайЛаем (Plai Laem)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'ПлайЛаем (Plai Laem)' && navigation == 1}">- ПлайЛаем </a></li>
                            <li><a @click="filter.place='ЧонгМон (Cheng Mon)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'ЧонгМон (Cheng Mon)' && navigation == 1}">- ЧонгМон </a></li>
                            <li><a @click="filter.place='Чавенг (Chaweng)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Чавенг (Chaweng)' && navigation == 1}">- Чавенг </a></li>
                            <li><a @click="filter.place='Чавенг Ной (Chaweng Noi)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Чавенг Ной (Chaweng Noi)' && navigation == 1}">- Чавенг Ной </a></li>
                            <li><a @click="filter.place='Ламай (Lamai)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Ламай (Lamai)' && navigation == 1}">- Ламай </a></li>
                            <li><a @click="filter.place='ХуаТанон (Hua Thanon)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'ХуаТанон (Hua Thanon)' && navigation == 1}">- ХуаТанон </a></li>
                            <li><a @click="filter.place='Талингнам (Taling Ngam)'; applyFilter(0)" :class="{'fw-bold': $props.type=='Аренда' && filter.place == 'Талингнам (Taling Ngam)' && navigation == 1}">- Талингнам </a></li>
                        </ul>

                        <a class="mt-2" href="/agent/Покупка" :class="{'fw-bold': $props.type=='Покупка' && filter.place == '' && navigation == 1}">- Продажа</a>
                        <a class="mt-2" href="/agent/Транспорт" :class="{'fw-bold': $props.type=='Транспорт' && filterMoto.type == '' && navigation == 1}">Транспорт</a>
                        <ul class="ps-2" style="list-style-type: none; font-size: 0.9em;" v-if="$props.type == 'Транспорт' && (navigation == 1 || navigation == 6)">
                            <li><a @click="filter.place='moto'; applyFilter(0)" :class="{'fw-bold': $props.type=='Транспорт' && filter.place == 'moto' && navigation == 1}">- Мотоциклы </a></li>
                            <li><a @click="filter.place='auto'; applyFilter(0)" :class="{'fw-bold': $props.type=='Транспорт' && filter.place == 'auto' && navigation == 1}">- Авто </a></li>
                            <li><a class="" @click="navigation = 6" v-if="(navigation == 6 || navigation == 1) && $props.type=='Транспорт'" :class="{'fw-bold': navigation == 6}">- Добавить</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-10 col-12 pe-0">

                    <!--Список объектов-->
                    <table class="table" v-if="navigation == 1 && ($props.type == 'Аренда' || $props.type == 'Покупка')">
                        <thead>
                            <tr>
                                <th scope="col"><input type="checkbox" @change="checkAllItems" v-model="checkAllItemsValue"></th>
                                <th scope="col">Фото</th>
                                <th scope="col">Наименование</th>
                                <th scope="col" style="cursor: pointer;" @click="filterByCode()">Код <span v-if="filter.codeFilter == 1"> &darr; </span><span v-if="filter.codeFilter == 2"> &uarr; </span></th>
                                <th scope="col">Район</th>
                                <th scope="col"><img src="../assets/bedrooms.png" style="width: 25px;" alt=""></th>
                                <th scope="col"><img src="../assets/bathrooms.png" style="width: 25px;" alt=""></th>
                                <th scope="col">Актуальность</th>
                                <!--<th scope="col">Статус</th>-->
                                <th scope="col">Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index" style="font-size: 0.8em;">
                                <td>
                                    <input type="checkbox" v-model="checkedItems[item.id]" :name="item.id">
                                    
                                </td>
                                <td @click="$router.push('/items/'+item.id)" style="cursor: pointer;"><img v-lazy="'https://samui31.com/api/images_mini/'+JSON.parse(item.images)[0]" style="width: 40px; aspect-ratio: 1/1; object-fit: cover;" alt=""></td>
                                <td @click="$router.push('/items/'+item.id)" style="cursor: pointer;">{{ item.title }}</td>
                                <td style="width: fit-content; "><span style="min-width: 100px; display: block">{{ item.number }}</span></td>
                                <td >{{ item.place }}</td>
                                <td>{{ item.bedrooms }}</td>
                                <td>{{ item.bathrooms }}</td>
                                <td>{{ item.status }} {{ item.status_text }}</td>
                                <!--<td v-if="item.hide == 1">В архиве</td>
                                <td v-else>Опубликован</td>-->
                                <td>
                                    <div class="d-flex justify-content-center">
                                         <!--<a v-if="item.hide == 1" @click="archiveItem(item.id, 0)" class="me-3"><img src="../assets/publish.png" style="width: 20px;" alt=""></a>
                                         <a v-else @click="archiveItem(item.id, 1)" class="me-3"><img src="../assets/hide.png" style="width: 20px;" alt=""></a>-->
                                         <a @click="getEditItem(item.id)" data-bs-toggle="modal" data-bs-target="#offerModal"><img src="../assets/edit.png" style="width: 20px;" alt="" ></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="order-pagination py-4" v-if="navigation == 1 && ($props.type == 'Аренда' || $props.type == 'Покупка')">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pb-3 justify-content-center">
                        <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" :key="pageNum" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                            <a href="#" class="pagination-button">{{ pageNum }}</a>
                        </li>
                        </ul>
                    </nav>
                    </div>

                    <!--Создание объекта-->
                    <form @submit.prevent="createItem()" id="createForm" class="catalog-filter-block d-flex flex-column col-lg-6 pt-2" v-if="navigation == 2">
                        <!--<h1 class="catalog-page-title mb-5">Добавить объект</h1>-->

                        <select class="form-select py-2 mb-4" v-model="newItem.type" required>
                            <option value="" selected>Выбрать тип</option>
                            <option>Аренда</option>
                            <option>Покупка</option>
                        </select>

                        <p class="mb-1">Номер объекта</p>
                        <input type="text" class="form-control mb-4" v-model="newItem.number" placeholder="Номер объекта" required>
                        <p class="mb-1">Название</p>
                        <input type="text" class="form-control mb-4" v-model="newItem.title" placeholder="Дом на острове Самуи" required>
                        <p class="mb-1">Описание</p>
                        <textarea rows="5" class="form-control mb-4" v-model="newItem.description" required></textarea>
                        <p class="mb-1">Фото</p>
                        <input type="file" id="images" ref="imagesCreate" class="form-control mb-4" multiple @change="handleFileUploadAll()">
                        <p class="mb-1">Кол-во спален</p>
                        <input type="number" class="form-control mb-4" placeholder="1" v-model="newItem.bedrooms" required>
                        <p class="mb-1">Кол-во санузлов</p>
                        <input type="number" class="form-control mb-4" placeholder="1" v-model="newItem.bathrooms">
                        <select class="form-select py-2" v-model="newItem.place" required>
                            <option value="" selected>Выбрать район</option>
                            <option>Липаной (Lipa Noi)</option>
                            <option>Натон (Nathon)</option>
                            <option>БангПо (Bang Po)</option>
                            <option>БанТай (Ban Tai)</option>
                            <option>Майнам (Maenam)</option>
                            <option>БоПут (Bo Phut)</option>
                            <option>БангРак (BangRak)</option>
                            <option>ПлайЛаем (Plai Laem)</option>
                            <option>ЧонгМон (Cheng Mon)</option>
                            <option>Чавенг (Chaweng)</option>
                            <option>Чавенг Ной (Chaweng Noi)</option>
                            <option>Ламай (Lamai)</option>
                            <option>ХуаТанон (Hua Thanon) </option>
                            <option>Талингнам (Taling Ngam) </option>
                        </select>

                        <p class="mt-4 mb-1">Расстояние до пляжа (м)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.beach">
                        <p class="mb-1">Расстояние до магазина (м)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.shop">
                        <p class="mb-1">Площадь (м2)</p>
                        <input type="number" class="form-control mb-4" placeholder="100" v-model="newItem.size" required>

                        <p class="mb-1">Цена (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="100000" v-model="newItem.price" required>

                        <p class="mb-1" v-if="newItem.type !== 'Покупка'">Депозит (฿)</p>
                        <input type="number" class="form-control mb-4" v-if="newItem.type !== 'Покупка'" placeholder="10000" v-model="newItem.deposit">

                        <p class="mb-1">Комиссия агента (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.commission">

                        <p class="mb-1">Ст-мость электричества за 1кВт (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.energy">

                        <p class="mb-1">Ст-мость воды (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.water">

                        <p class="mb-2">Расчет воды за:</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="water_1" v-model="newItem.waterType">
                                <label class="form-check-label" for="water_1">
                                    месяц
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="water_2" v-model="newItem.waterType">
                                <label class="form-check-label" for="water_2">
                                    человека в месяц
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="2" id="water_3" v-model="newItem.waterType">
                                <label class="form-check-label" for="water_3">
                                    за 1 куб
                                </label>
                            </div>
                        </div>

                        <p class="mt-4 mb-1" v-if="newItem.type !== 'Покупка'">Ст-мость интернета (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.internetPrice"  v-if="newItem.type !== 'Покупка'">

                        <p class="mb-1" v-if="newItem.type !== 'Покупка'">Скорость интернета (мб/с)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.internetSpeed"  v-if="newItem.type !== 'Покупка'">

                        <p class="mb-1" v-if="newItem.type !== 'Покупка'">Ст-мость уборки перед выездом (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.cleaning" v-if="newItem.type !== 'Покупка'">
                        
                        <p class="mb-1" v-if="newItem.type !== 'Покупка'">Актуальность</p>
                        <select class="form-select py-2 mb-2" v-model="newItem.status" required v-if="newItem.type !== 'Покупка'">
                            <option>Под запрос</option>
                            <option>Свободен</option>
                            <option>Занят</option>
                        </select>
                        <input type="text" class="form-control mb-4" placeholder="до 1 августа" v-model="newItem.status_text" v-if="newItem.type !== 'Покупка'">

                        <p class="mb-1" v-if="newItem.type !== 'Покупка'">Контакты для бронирования</p>
                        <p class="mb-1" v-else>Контакты агента</p>
                        <input type="text" class="form-control mb-4" placeholder="@samui_31" v-model="newItem.contacts">
                        
                        <p class="mt-4 mb-2">Удобства</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="pool" id="service_9" v-model="newItem.service">
                                <label class="form-check-label" for="service_9">
                                    Бассейн
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="gym" id="service_1" v-model="newItem.service">
                                <label class="form-check-label" for="service_1">
                                    Тренажерный зал
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="cond" id="service_15" v-model="newItem.service">
                                <label class="form-check-label" for="service_15">
                                    Кондиционер
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="tv" id="service_5" v-model="newItem.service">
                                <label class="form-check-label" for="service_5">
                                    Телевизор
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="wm" id="service_4" v-model="newItem.service">
                                <label class="form-check-label" for="service_4">
                                    Стиральная машина
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="dw" id="service_2" v-model="newItem.service">
                                <label class="form-check-label" for="service_2">
                                    Посудомоечная машина
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="fridge" id="service_3" v-model="newItem.service">
                                <label class="form-check-label" for="service_3">
                                    Холодильник
                                </label>
                            </div>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="internet" id="service_6" v-model="newItem.service">
                                <label class="form-check-label" for="service_6">
                                    Интернет
                                </label>
                            </div>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="plate" id="service_7" v-model="newItem.service">
                                <label class="form-check-label" for="service_7">
                                    Плита
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="oven" id="service_16" v-model="newItem.service">
                                <label class="form-check-label" for="service_16">
                                    Духовка
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="hood" id="service_17" v-model="newItem.service">
                                <label class="form-check-label" for="service_17">
                                    Вытяжка
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="micro" id="service_8" v-model="newItem.service">
                                <label class="form-check-label" for="service_8">
                                    Микроволновка
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="kettle" id="service_10" v-model="newItem.service">
                                <label class="form-check-label" for="service_10">
                                    Чайник
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="coffee" id="service_12" v-model="newItem.service">
                                <label class="form-check-label" for="service_12">
                                    Кофемашина
                                </label>
                            </div>

                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="toster" id="service_11" v-model="newItem.service">
                                <label class="form-check-label" for="service_11">
                                    Тостер
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="multi" id="service_13" v-model="newItem.service">
                                <label class="form-check-label" for="service_13">
                                    Мультиварка
                                </label>
                            </div>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="blender" id="service_14" v-model="newItem.service">
                                <label class="form-check-label" for="service_14">
                                    Блендер
                                </label>
                            </div>
                        </div>

                        <p class="mt-4 mb-2" v-if="newItem.type !== 'Покупка'">Особенности</p>
                        <div class="ms-3" v-if="newItem.type !== 'Покупка'">
                            <label class="mb-2">С питомцами:</label>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="special_1" v-model="newItem.animals">
                                <label class="form-check-label" for="special_1">
                                    Разрешается
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="2" id="special_2" v-model="newItem.animals">
                                <label class="form-check-label" for="special_2">
                                    Обсуждается
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="special_3" v-model="newItem.animals">
                                <label class="form-check-label" for="special_3">
                                    Запрещается
                                </label>
                            </div>
                        </div>

                        <p class="mb-1">Локация</p>
                        <!--<textarea rows="5" class="form-control mb-4" v-model="newItem.map"></textarea>-->
                        <input type="file" id="imageMap" ref="imageMapCreate" class="form-control mb-4" @change="handleFileUploadMap()">

                        <p class="mb-1">Видео</p>
                        <textarea rows="5" class="form-control mb-4" v-model="newItem.video"></textarea>

                        <p class="mb-1 d-none">Похожие объекты</p>
                        <textarea rows="5" class="form-control mb-4 d-none" v-model="newItem.analog"></textarea>

                        <!--<p class="mb-2">Публикация</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="hide_1" v-model="newItem.hide">
                                <label class="form-check-label" for="hide_1">
                                    Основной каталог
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="hide_2" v-model="newItem.hide">
                                <label class="form-check-label" for="hide_2">
                                    Архив
                                </label>
                            </div>
                        </div>-->

                        
                        <button class="btn-white ms-auto mt-5"><span :class="{'d-none': loader}">Создать объект</span>
                            <div class="spinner-border text-secondary" :class="{'d-none': !loader}" role="status">
                                <span class="visually-hidden">Загрузка...</span>
                            </div>
                        </button>
                    </form>

                        <!--Создание объекта транспорта-->
                    <form @submit.prevent="createMoto()" id="createFormMoto" class="catalog-filter-block d-flex flex-column col-lg-6 pt-2" v-if="navigation == 6">
                        <!--<h1 class="catalog-page-title mb-5">Добавить объект</h1>-->

                        <select class="form-select py-2 mb-4" v-model="newItem.type" required>
                            <option value="" selected>Вид транспорта</option>
                            <option>Авто</option>
                            <option>Мотоцикл</option>
                            <option>Скутер</option>
                        </select>

                        <p class="mb-1">Номер транспорта</p>
                        <input type="text" class="form-control mb-4" v-model="newItem.number" placeholder="Введите номер" required>

                        <p class="mb-1">Марка</p>
                        <input type="text" class="form-control mb-4" v-model="newItem.mark" placeholder="Honda" required>

                        <p class="mb-1">Модель</p>
                        <input type="text" class="form-control mb-4" v-model="newItem.model" placeholder="RX500" required>

                        <p class="mb-1" v-if="newItem.type == 'Авто'">Объем двигателя (л.)</p>
                        <p class="mb-1" v-else>Объем двигателя (куб.)</p>
                        <input type="text" class="form-control mb-4" v-model="newItem.engine" placeholder="100" required>

                        <p class="mb-1">Год выпуска</p>
                        <input type="number" class="form-control mb-4" v-model="newItem.year" placeholder="2010" required>

                        <p class="mb-1">Пробег (км)</p>
                        <input type="number" class="form-control mb-4" v-model="newItem.probeg" placeholder="1000">

                        <p class="mb-1">Фото</p>
                        <input type="file" id="images" ref="imagesCreate" class="form-control mb-4" multiple @change="handleFileUploadAll()">

                        <p class="mb-1">Описание</p>
                        <textarea rows="5" class="form-control mb-4" v-model="newItem.description"></textarea>
                        
                        <p class="mb-1">Стоимость за сутки (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="100" v-model="newItem.priceForDay">

                        <p class="mb-1">Стоимость за неделю (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="500" v-model="newItem.priceForWeek">

                        <p class="mb-1">Стоимость за месяц (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="2000" v-model="newItem.priceForMonth">

                        <p class="mb-1">Депозит (฿)</p>
                        <input type="number" class="form-control mb-4" placeholder="1000" v-model="newItem.deposit">

                        <p class="mb-1">Контакты для бронирования</p>
                        <input type="text" class="form-control mb-4" placeholder="@samui_31" v-model="newItem.contacts">

                        <p class="mb-1">Локация</p>
                        <input type="file" id="imageMap" ref="imageMapCreate" class="form-control mb-4" @change="handleFileUploadMap()">

                        <!--<p class="mb-2">Публикация</p>
                        <div class="ms-3">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="0" id="hide_1" v-model="newItem.hide">
                                <label class="form-check-label" for="hide_1">
                                    Основной каталог
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="1" id="hide_2" v-model="newItem.hide">
                                <label class="form-check-label" for="hide_2">
                                    Архив
                                </label>
                            </div>
                        </div>-->

                        
                        <button class="btn-white ms-auto mt-5"><span :class="{'d-none': loader}">Создать транспорт</span>
                            <div class="spinner-border text-secondary" :class="{'d-none': !loader}" role="status">
                                <span class="visually-hidden">Загрузка...</span>
                            </div>
                        </button>
                    </form>

                    <!--Список транспорта-->
                    <table class="table" v-if="navigation == 1 && $props.type == 'Транспорт'">
                        <thead>
                            <tr>
                                <th scope="col"><input type="checkbox" @change="checkAllItems" v-model="checkAllItemsValue"></th>
                                <th scope="col">Фото</th>
                                <th scope="col">Тип транспорта</th>
                                <th scope="col">Код</th>
                                <th scope="col">Марка</th>
                                <th scope="col">Модель</th>
                                <th scope="col">Год вып.</th>
                                <th scope="col">Пробег</th>
                                <th scope="col">Объем</th>
                                <th scope="col">Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index" style="font-size: 0.8em;">
                                <td>
                                    <input type="checkbox" v-model="checkedItems[item.id]" :name="item.id">
                                    
                                </td>
                                <td @click="$router.push('/moto/'+item.id)" style="cursor: pointer;"><img v-lazy="'https://samui31.com/api/images_mini/'+JSON.parse(item.images)[0]" style="width: 40px; aspect-ratio: 1/1; object-fit: cover;" alt=""></td>
                                <td @click="$router.push('/moto/'+item.id)" style="cursor: pointer;">{{ item.type }}</td>
                                <td style="width: fit-content; "><span style="min-width: 100px; display: block">{{ item.number }}</span></td>
                                <td >{{ item.mark }}</td>
                                <td>{{ item.model }}</td>
                                <td>{{ item.year }}</td>
                                <td>{{ item.probeg }}</td>
                                <td>{{ item.engine }}</td>
                                <td>
                                    <div class="d-flex justify-content-center">
                                         <!--<a v-if="item.hide == 1" @click="archiveItem(item.id, 0)" class="me-3"><img src="../assets/publish.png" style="width: 20px;" alt=""></a>
                                         <a v-else @click="archiveItem(item.id, 1, 'Транспорт')" class="me-3"><img src="../assets/hide.png" style="width: 20px;" alt=""></a>-->
                                         <a @click="getEditItem(item.id)" data-bs-toggle="modal" data-bs-target="#motoModal"><img src="../assets/edit.png" style="width: 20px;" alt="" ></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="order-pagination py-4" v-if="navigation == 1 && $props.type == 'Транспорт'">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pb-3 justify-content-center">
                        <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" :key="pageNum" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                            <a href="#" class="pagination-button">{{ pageNum }}</a>
                        </li>
                        </ul>
                    </nav>
                    </div>

                </div>

        </div>
    </div>
</template>

<style lang="scss">
@import url("@vueform/slider/themes/default.css");
</style>

<script>
import axios from 'axios'

export default{
    props: {
        type: String
    },

    data(){
        return{
            images: '',
            imagesCreate: '',
            file: '',
            imgInputs: 0,
            imagesPreview: [],
            newItem: {
                type: '',
                place: '',
                service: [],
                animals: '0',
                waterType: '0',
                hide: '1',
                map: '',
                agent: this.$store.state.user_info.id
            },
            loader: false,
            navigation: 1,

            priceSlider: {
                value: [0, 0],
                max: 1000000,
                min: 60000,
                tooltipPosition: 'bottom',
                step: 500,
                format: {
                    prefix: '฿',
                    thousand: ' ',
                    encoder: v=> Math.round(v)
                }
            },
            priceSliderHidden: {
                value: [0, 0],
                max: 1000000,
                min: 60000,
                tooltipPosition: 'bottom',
                step: 500,
                format: {
                    prefix: '฿',
                    thousand: ' ',
                    encoder: v=> Math.round(v)
                }
            },
            filter: {
                place: '',
                service: [],
                animals: [],
                beach: '',
                bedroom: [
                   
                ],
                number: '',
                codeFilter: 0
            },
            filterMoto: {
                type: ''
            },
            items: [],
            editItem: {},
            hiddenItems: [],
            offers: [],
            reviews: [],
            page: 0,
            pages_count: 0,
            reviewPhotoLoader: -1,
            checkedItems: {},
            checkAllItemsValue: false,
            image_menu: '',
            image_footer: '',
            image_main: '',
            users: [],
            agents: [],
            newAgent: ''
        }
    },
    methods: {
        handleFileUploadAll(){
            this.imagesCreate = this.$refs.imagesCreate.files
        },
        handleFileUploadMap(){
            this.newItem.map = this.$refs.imageMapCreate.files
        },
        handleFileUploadEditMap(){
            this.editItem.map = this.$refs.imageMap.files
        },
        handleFilesUpload(index){
            console.log(index)
            function fileListFrom (files) {
                const b = new ClipboardEvent("").clipboardData || new DataTransfer()
                for (const file of files) b.items.add(file)
                return b.files
            }
            const newArr =  Array.from(this.$refs.images.files)
            newArr[index] = event.target.files[0]
            this.$refs.images.files = fileListFrom(newArr);
            this.images = this.$refs.images.files
            this.imagesPreview[index] = URL.createObjectURL(this.$refs.images.files[index])
            console.log(this.images)
        },
        clearFiles(){
            function fileListFrom (files) {
                const b = new ClipboardEvent("").clipboardData || new DataTransfer()
                for (const file of files) b.items.add(file)
                return b.files
            }
            const newArr =  []
            this.$refs.images.files = fileListFrom(newArr);
            this.$refs.imageMap.files = fileListFrom(newArr);
            this.imagesPreview = []
            this.imgInputs = 1
            this.images = []
        },
        deleteImgInput(){
            function fileListFrom (files) {
                const b = new ClipboardEvent("").clipboardData || new DataTransfer()
                for (const file of files) b.items.add(file)
                return b.files
            }
            const newArr =  Array.from(this.$refs.images.files)
            if(newArr[this.imgInputs-1]) newArr.pop()
            this.imagesPreview.pop()
            this.$refs.images.files = fileListFrom(newArr);
            this.images = this.$refs.images.files
            this.imgInputs--;
            console.log(this.images)
            if(this.imgInputs == 0) this.imgInputs = 1
        },
        handleFilesChangedUpload(){
            this.images = this.$refs.images.files
            console.log(this.images)
        },
        createItem(){
            if(document.querySelector('#createForm').reportValidity()){
                this.loader = true
            }
            else{
                alert('Заполните форму корректно')
            }
            
            let formData = new FormData()

            for( let i = 0; i < this.imagesCreate.length; i++ ){
                let image = this.imagesCreate[i]
                formData.append('images[' + i + ']', image)
                
            }
            if(this.newItem.map !== '') formData.append('images['+ this.imagesCreate.length +']', this.newItem.map[0]);
            

            axios.post( 'https://samui31.com/api/upload_images.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response)=>{
                if(response.data == 'Файлы загружены'){
                    let images_array = []
                    for( let i = 0; i < this.imagesCreate.length; i++ ){
                        images_array.push(this.imagesCreate[i].name)
                    }
                    if(this.newItem.map !== '') this.newItem.map = this.newItem.map[0].name
                    this.newItem['images'] = JSON.stringify(images_array)
                    axios.post('https://samui31.com/api/create_item.php', this.newItem).then((response)=>{
                        if(response.data == 1){
                            alert('Успешно!')
                            this.sendTGMessage(`Агент ${this.$store.state.user_info.name} добавил новый объект "${this.newItem.title}" с номером ${this.newItem.number}`, this.newItem.type)
                            
                        }
                        else{
                            alert('Произошла ошибка!')
                            window.location.reload()
                        }
                    })
                }
            })
        },
        createMoto(){
            if(document.querySelector('#createFormMoto').reportValidity()){
                this.loader = true
            }
            else{
                alert('Заполните форму корректно')
            }
            
            let formData = new FormData()

            for( let i = 0; i < this.imagesCreate.length; i++ ){
                let image = this.imagesCreate[i]
                formData.append('images[' + i + ']', image)
                
            }
            if(this.newItem.map !== '') formData.append('images['+ this.imagesCreate.length +']', this.newItem.map[0]);
            

            axios.post( 'https://samui31.com/api/upload_images.php',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then((response)=>{
                if(response.data == 'Файлы загружены'){
                    let images_array = []
                    for( let i = 0; i < this.imagesCreate.length; i++ ){
                        images_array.push(this.imagesCreate[i].name)
                    }
                    if(this.newItem.map !== '') this.newItem.map = this.newItem.map[0].name
                    this.newItem['images'] = JSON.stringify(images_array)
                    axios.post('https://samui31.com/api/create_moto.php', this.newItem).then((response)=>{
                        if(response.data == 1){
                            alert('Успешно!')
                            this.sendTGMessage(`Агент ${this.$store.state.user_info.name} добавил новый транспорт "${this.newItem.title}" с номером ${this.newItem.number}`, 'Транспорт')
                            
                        }
                        else{
                            alert('Произошла ошибка!')
                            console.log(response)
                        }
                    })
                }
            })
        },
        loadData(get_param){
            if(get_param){
                axios.get('https://samui31.com/api/get_items_admin.php?' + get_param).then((response)=>{
                    console.log(response.data)
                    this.items = response.data[0]
                    this.pages_count = response.data[1]
                })
            }
            else{
                if(window.location.search.length == 0) window.location.search = '?type=' + this.$props.type + `&page=0&agent=${this.$store.state.user_info.id}`
                axios.get('https://samui31.com/api/get_items_admin.php' + window.location.search).then((response)=>{
                    console.log(response.data)
                    this.items = response.data[0]
                    this.pages_count = response.data[1]
                }) 
            }

        },
        getFilterData(){
            axios.post('https://samui31.com/api/get_filter_data.php', {type: this.$props.type, agent: this.$store.state.user_info.id}).then((response)=>{
                this.priceSlider.value = response.data
                this.priceSlider.min = Number(response.data[0])
                this.priceSlider.max = Number(response.data[1])
                console.log(response.data)
            })
        },
        getParam(){
            let get_param = ''
            get_param+="page=" + this.page
            get_param+=`&agent=${this.$store.state.user_info.id}`
            if(this.filter.place !== '') get_param += '&place=' + this.filter.place
            for(let i=0; i<this.filter.service.length; i++){
                if(get_param.length>0) get_param += '&'
                get_param += this.filter.service[i] + '=1'
            }
            for(let i=0; i<this.filter.animals.length; i++){
                if(get_param.length>0) get_param += '&'
                get_param += '&animals[]=' + this.filter.animals[i]
            }
            if(get_param.length>0) get_param += '&'
            get_param += 'minprice=' + this.priceSlider.value[0]
            if(get_param.length>0) get_param += '&'
            get_param += 'maxprice=' + this.priceSlider.value[1]
            if(this.filter.beach !== '') get_param += '&beach=' + this.filter.beach
            for(let i=0; i<this.filter.bedroom.length; i++){
                get_param += '&bedroom[]=' + this.filter.bedroom[i]
            }
            if(this.filter.number !== '') get_param += '&number=' + this.filter.number
            get_param = get_param + '&type=' + this.$props.type
            get_param += '&code_filter=' + this.filter.codeFilter

            return get_param
        },

        applyFilter(page){
            this.page=page;
            console.log(this.getParam())
            this.loadData(this.getParam())
        },

        getEditItem(id){
            axios.get('https://samui31.com/api/get_item.php?id='+id+'&type='+this.$props.type).then((response)=>{
                this.editItem = response.data
                console.log(this.editItem)

            })

        },
        deleteItem(id){
            console.log(id)
            let mess = confirm('Вы точно хотите удалить объект?')
            if(mess == true){
                axios.post('https://samui31.com/api/delete_item.php', {item_id: id}).then((response)=>{
                    if(response.data == 1){
                        alert('Объект удален')
                        window.location.reload()
                    }
                    else{
                        alert('Произошла ошибка')
                    }
                })
            }
            
        },
        uploadEditItem(){
            console.log(this.images)
                this.loader = true
                this.editItem['type_item'] = this.$props.type
                console.log(this.editItem)
                if(this.images !== '' && this.images.length > 0){
                    let formData = new FormData()

                    for( let i = 0; i < this.images.length; i++ ){
                        let image = this.images[i]
                        formData.append('images[' + i + ']', image)
                    }
                    if(typeof this.editItem.map  === 'object') formData.append('images['+ this.images.length +']', this.editItem.map[0]);

                    axios.post( 'https://samui31.com/api/upload_images.php',
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((response)=>{
                        console.log(this.images)
                        console.log(2)
                        if(response.data == 'Файлы загружены'){
                            let images_array = []
                            for( let i = 0; i < this.images.length; i++ ){
                                images_array.push(this.images[i].name)
                            }
                            if(typeof this.editItem.map  === 'object') this.editItem.map = this.editItem.map[0].name
                            this.editItem['images'] = JSON.stringify(images_array)
                            this.editItem['type_item'] = this.$props.type
                            axios.post('https://samui31.com/api/edit_item.php', this.editItem).then((response)=>{
                                if(response.data == 1){
                                    alert('Успешно!')
                                    this.loader = false
                                    this.loadData(this.getParam())
                                }
                                else{
                                    alert('Произошла ошибка!')
                                    this.loader = false
                                }
                            })
                        }
                    })
                }
                else{
                    if(typeof this.editItem.map  === 'object'){
                        let formData = new FormData()
                        console.log(1)
                        console.log(this.editItem.map)

                        formData.append('images[0]', this.editItem.map[0]);

                        axios.post( 'https://samui31.com/api/upload_images.php',
                            formData,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                        ).then((response)=>{
                            if(response.data == 'Файлы загружены'){
                                this.editItem.map = this.editItem.map[0].name
                                this.editItem['type_item'] = this.$props.type
                                axios.post('https://samui31.com/api/edit_item.php', this.editItem).then((response)=>{
                            
                                    if(response.data == 1){
                                        alert('Успешно!')
                                        this.loader = false
                                        this.loadData(this.getParam())
                                    }
                                    else{
                                        alert('Произошла ошибка!')
                                        console.log(response.data)
                                        this.loader = false
                                    }
                                })
                            }
                        })

                    }

                    else{
                        console.log(3)
                        this.editItem['type_item'] = this.$props.type
                        axios.post('https://samui31.com/api/edit_item.php', this.editItem).then((response)=>{
                            
                            if(response.data == 1){
                                alert('Успешно!')
                                this.loader = false
                                this.loadData(this.getParam())
                            }
                            else{
                                alert('Произошла ошибка!')
                                console.log(response.data)
                                this.loader = false
                            }
                        })
                    }
                }
            
        },

        // archiveItem(id, hide){
        //     axios.post('https://samui31.com/api/archive_item.php', {id: id, hide: hide, type: this.$props.type}).then(()=>{
        //         this.loadData(this.getParam())
        //     })
        // },

        checkAllItems(){
            this.checkedItems = {}
            if(this.checkAllItemsValue == true){
                this.items.forEach(item => {
                    this.checkedItems[item.id] = true
                });
            }

        },

        deleteItems(){
            if(confirm('Вы точно хотите удалить выбранные объекты?')){
                let deleteArr = []
                Object.keys(this.checkedItems).forEach(value => {
                    if(this.checkedItems[value]) deleteArr.push(value)
                })

                axios.post('https://samui31.com/api/delete_item_agent.php', {item_id: deleteArr, type: this.$props.type}).then(()=>{
                    this.loadData(`type=${this.$props.type}&page=0&agent=${this.$store.state.user_info.id}`)
                })
            }
            
        },

        filterByCode(){
            if(this.filter.codeFilter == 0){
                this.filter.codeFilter = 1
            }
            else if(this.filter.codeFilter == 1){
                this.filter.codeFilter = 2
            }
            else if(this.filter.codeFilter == 2){
                this.filter.codeFilter = 1
            }

            this.applyFilter(0)
           
        },
        sendTGMessage(message, type){
            const telegramBotToken = '7669023066:AAF8RKvJGYAK_Ng8ItGY0osumbIQyH5wUD0'
            const chatId = '-1002408785776';
            let data = {
                chat_id: chatId,
                text: message
            }
            
            axios.post(`https://samui31.com/api/send_mail.php`, {message: message, type: type}).then(()=>{
                axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data).then(()=>{window.location.reload()})
            })
        }
    },

    beforeCreate(){
        if(this.$store.state.user_info.mail !== 'admin@tai' && this.$store.state.user_info.agent == 0){
            window.location.href = '/lk'
        }
    },

    created(){
        this.getFilterData()
        this.loadData(`type=${this.$props.type}&page=0&agent=${this.$store.state.user_info.id}`)
    }
}
</script>